<template>
  <list-template
      style="padding-top: 10rem"
      :loading="loading"
      :search-config="searchConfig"
      @onSearch="onSearch"
      :table-config="tableConfig"
      :table-data="tableData"
      :total="total"
      :current-page="page"
      @onChangePage="handleCurrentChange"
      @onHandle="handleEdit"
      @onReset="onReset"
      ref="table"
  ></list-template>
</template>

<script>
import config from "./common";
import {mapState} from "vuex";

export default {
  name: "Table_one",
  data() {
    return {
      loading: true,
      search: {},
      search_type: [],
      searchConfig_teaching: [],
      searchConfig: [
        {prop: 'student_name', placeholder: '搜索学生姓名'},
        {
          prop: 'school_id', tag: "select", placeholder: '筛选校区', options: [],
          label: 'name', value: 'id',
          change: (e, search) => this.schoolChange(e, search)
        },
        {
          prop: 'grade_id', tag: "select", placeholder: '筛选年级', options: [],
          label: 'name', value: 'id',
          change: (e, search) => this.gradeChange(e, search)
        },
        {
          prop: 'teaching_id', tag: "select", placeholder: '筛选班级', options: [],
          label: 'name', value: 'id',
          change: (e, search) => this.classChange(e, search),
        },
        {
          prop: 'subject_id', tag: "select", placeholder: '筛选提问科目', options: [],
          label: 'name', value: 'subject_id',
          change: (e, search) => this.subjectChange(e, search)
        },
        config.timeoutConfig
      ],
      tableConfig: [
        config.studentProp,
        config.teachingProp,
        config.subjectProp,
        config.teacherProp,
        config.questionProp,
        config.solveProp,
        config.gradeProp,
        config.schoolProp,
        {prop: 'handle', label: '操作', width: '120rem', handle: true, render: (row) => row.button == 1 ? [{ type:"answer", text:"解答" }] : [{ type:"view", text:"查看" }]}
      ],

      tableData: [],
      //总条数
      total: 0,
      pageCount: 0,
    }
  },
  props: {
    type: Number,
    activeName: String,
  },
  mounted() {
    this.searchConfig[0].default = this.$route.query.name||''
    this?.$refs?.table?.$refs?.searchBlock.setDefaultSearchValue(['student_name'])
    this.search.student_name = this.$route.query.name||''
    this.getSchool()
    if(this.activeName == this.type){
      this.getData()
    }
    if (this.type === 2) {
      this.searchConfig.push(config.feedbackConfig)
      this.tableConfig = this.tableConfig.slice(0, 6).concat([config.feedbackProp]).concat(this.tableConfig.slice(6))
    }
    if (this.type === 4) {
      this.searchConfig = this.searchConfig.concat([config.feedbackConfig, config.solve_statusConfig])
      this.tableConfig = this.tableConfig.slice(0, 6).concat([config.feedbackProp, config.solveProp2]).concat(this.tableConfig.slice(6))
    }
    if (this.type === 5) {
      this.tableConfig = this.tableConfig.slice(0, 6).concat([config.closeProp]).concat(this.tableConfig.slice(6))
    }
    if (this.type === 0) {
      this.searchConfig = this.searchConfig.concat([config.feedbackConfig, config.solve_statusConfig])
      this.tableConfig = this.tableConfig.slice(0, 6).concat([config.feedbackProp, config.solveProp2]).concat(this.tableConfig.slice(6))
    }
  },
  activated() {
    this.searchConfig[0].default = this.$route.query.name||''
    this?.$refs?.table?.$refs?.searchBlock.setDefaultSearchValue(['student_name'])
    if(this.activeName == this.type){
      this.getData()
    }
  },
  computed: {
    ...mapState(['page'])
  },
  watch: {
    activeName(n,o){
      if(n == this.type) this.getData()
    }
  },
  methods: {
    getSchool() {
      this.$_axios2.get('api/common/school', {
        params: {
          level: 2,
          ispeiyou: 1
        }
      }).then(res => {
        let data = res.data.data;
        this.searchConfig[1].options = data.school
        this.searchConfig[4].options = data.subject_data
      })
    },

    schoolChange(e, search) {
      if (!this.search_type.includes(1)) this.search_type.push(1)
      search.grade_id = ''
      search.teaching_id = ''
      if (this.search_type.indexOf(1) === 0) {
        search.subject_id = ''
        this.search_type = [1]  //重置年级和科目的筛选顺序
      }
      this.searchConfig[3].options = []
      this.search = search
      let params = {school_id: search.school_id, level: 2, ispeiyou: 1}
      if (this.search_type.indexOf(1) === 1) params.subject_id = search.subject_id
      this.$_axios2.get('api/common/school', {params}).then(res => {
        let data = res.data.data;
        this.searchConfig[2].options = data.grade
        if (this.search_type.indexOf(1) === 0) this.searchConfig[4].options = data.subject_data
      })

    },

    gradeChange(e, search) {
      if (!this.search_type.includes(2)) this.search_type.push(2)
      search.teaching_id = ''
      if (this.search_type.indexOf(2) === 1) search.subject_id = ''
      this.search = search
      let params = {
        school_id: search.school_id,
        grade_id: search.grade_id,
        level: 2,
        ispeiyou: 1
      }
      if (this.search_type.indexOf(2) === 2) params.subject_id = search.subject_id
      this.$_axios2.get('api/common/school', {params}).then(res => {
        let data = res.data.data;
        this.searchConfig[3].options = data.teaching_data
        if (this.search_type.indexOf(2) === 1) this.searchConfig[4].options = data.subject_data
      })
    },

    classChange(e, search) {
      if (this.search_type.indexOf(4) === -1) {
        this.searchConfig[3].options.forEach(item => {
          if (item.id === e) search.subject_id = item.subject_id
          this.search = search
        })
      }
    },

    subjectChange(e, search) {
      if (!this.search_type.includes(4)) this.search_type.push(4)
      search.teaching_id = ''
      let params = {subject_id: search.subject_id, level: 2, ispeiyou: 1}
      if (this.search_type.indexOf(4) === 0) {
        search.school_id = ''
        search.grade_id = ''
        this.searchConfig[2].options = []
        this.searchConfig[3].options = []
      } else if (this.search_type.indexOf(4) === 1) {
        search.grade_id = ''
        this.searchConfig[3].options = []
        params.subject_id = search.subject_id
        params.school_id = search.school_id
      } else {
        params.subject_id = search.subject_id
        params.school_id = search.school_id
        params.grade_id = search.grade_id
      }
      this.search = search
      this.$_axios2.get('api/common/school', {params}).then(res => {
        let data = res.data.data;
        if (this.search_type.indexOf(4) === 0) {
          this.searchConfig[1].options = data.school
        } else if (this.search_type.indexOf(4) === 1) {
          this.searchConfig[2].options = data.grade
        } else {
          this.searchConfig[3].options = data.teaching_data
        }
      })
    },

    getData() {
      this.loading = true;
      let params = {...this.search, page: this.page};
      console.log(this.search)

      this.tableData = []
      this.$_axios2('api/question/doubt/pc-list', {
        params: {
          ...params,
          status: this.activeName
        }
      }).then(res => {
        let data = res.data.data
        this.tableData = data.list
        this.total = data.page.total
      }).finally(() => {
        this.loading = false;
      })
    },

    onSearch(val) {
      this.$store.commit('setPage', 1)
      this.search = val;
      this.getData()
    },

    handleEdit(row, text) {
      this.$router.push('./details?status=' + row.status + '&id=' + row.id)
    },

    // 当前页改变触发
    handleCurrentChange(val) {
      //当前页赋值给currentPage
      this.$store.commit('setPage', val)
      //拉取数据
      this.getData()
    },

    onReset() {
      this.searchConfig[2].options = []
      this.searchConfig[3].options = []
    }
  }
}
</script>

<style scoped>

</style>
