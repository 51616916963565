const obj = [
  {label: '全部', value: 0},
  {label: '是', value: 1},
  {label: '否', value: 2},
]

const config = {
  timeoutConfig: {
    prop: 'timeout', tag: "select", placeholder: '筛选解答是否超时',
    options: obj
  },
  feedbackConfig: {
    prop: 'feedback', tag: "select", placeholder: '筛选反馈是否超时',
    options: obj
  },
  solve_statusConfig: {
    prop: 'solve_status', tag: "select", placeholder: '筛选是否解决问题',
    options: obj
  },
  studentProp: {prop: 'student_name', label: '学生姓名'},
  teachingProp: {prop: 'teaching_name', label: '班级'},
  subjectProp: {prop: 'subject_name', label: '提问科目'},
  teacherProp: {prop: 'teacher_name', label: '答疑教师'},
  questionProp: {prop: 'question_time', label: '问题提交时间'},
  gradeProp: {prop: 'grade_name', label: '年级'},
  solveProp: {prop: 'solve_time_describe', label: '解答是否超时'},
  feedbackProp: {prop: 'feedback_time_describe', label: '反馈是否超时'},
  solveProp2: {prop: 'solve_status_describe', label: '是否解决问题'},
  closeProp: {prop: 'close_time', label: '问题关闭时间'},
  schoolProp: {prop: 'school_name', label: '校区'},
}

export default config